import * as React from "react";
import './TextArea.css'

const TextArea = ({
  name,
  type,
  onChange,
  placeholder,
  required,
  value
}) => {

  return (
    <div className="TextAreaParent">
    <textarea
        className="TextArea"
        type={type}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        required={required}/>
    </div>
  );
};

export default TextArea;