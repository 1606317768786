import React, { useEffect, useState } from "react";
import './Home.css';

import TextField from "./Utils/TextField";

import logo from './logo.svg'
import phone from './happy.svg'

const Home = () => {
  const [message, setMessage] = useState("hey alda, I want to go to cool events!");

  const updateMessage = (msg) => {
    if (msg == "") {
      setMessage("hey alda, I want to go to cool events!")
    } else {
      setMessage(msg)
    }
  }

  function contact() {
    fetch('vcard.vcf').then(response => {
      response.blob().then(blob => {
          const fileURL = window.URL.createObjectURL(blob);
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = 'alda.vcf';
          alink.click();
      })
    })
  }

  return (
    <div className="Home">
        <div className='Promo'>
        <div className='Logo'>
         <img src={logo} style={{ maxHeight: "50px"}}/>
         </div>
         <div className='LogoCopy'>hey, i'm <b>alda</b></div>
         </div>

        <div className='TextUs'>
          <div>
            <img src={phone} style={{ maxHeight: "200px", verticalAlign: "middle"}}/>
            <div className="AboutUs">I find and curate events that are perfect for you...</div>
            <div className="Message">
              <input className="MessageField" placeholder={"What are you looking for?"} onChange={(event) => updateMessage(event.target.value)}></input>
              <a href={"sms:+15189295514&body=" + message} className="MessageButton">Text Me</a>
            </div>
        </div>
        </div>
    </div>
  );
};

export default Home;