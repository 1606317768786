import React from 'react';
import './ThankYou.css';

import happy from './happy.svg'

const ThankYou = () => {
  return (
    <div className="Home">
         <div className='ThankYou'>
            <div>
            <img src={happy} style={{ maxHeight: "200px", verticalAlign: "middle"}}/>
            <br></br>
            <div style={{fontSize: "50px"}}>thank <b>YOU</b> 🎉</div>
            <br></br>
            <div style={{fontSize: "25px", fontWeight: "250"}}>i'm happy you've joined</div>
            </div>
        </div>
    </div>
  );
};

export default ThankYou;