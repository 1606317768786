import React, {useEffect} from "react";
import './YesNoButton.css'
import Radio from "./Radio";

const YesNoButton = ({
  title,
  description,
  type,
  onChange,
  onClick,
  placeholder,
  required,
  imageUrl,
  clicked,
  value,
  yes=false,
  no=false
}) => {

  return (
    <div className="YesNoButton">
    {/* <img className="YesNoButtonImage" src={fire}></img> */}
    <div className="YesNoButtonImage" style={{backgroundImage: "url(" + imageUrl + ")"}}></div>
    <div className="YesNoText">
      <div className="YesNoButtonHeader">{title}</div>
      <div className="YesNoButtonDescription">{description}</div>
    </div>

    <div>
    <div className="YesNoButtons">
    <Radio value={"Yes"} selected={yes} onClick={() => clicked("yes")}/>
    </div>
    <div className="YesNoButtons">
    <Radio value={"No"} selected={no} onClick={() => clicked("no")}/>
    </div>
    </div>
    {/* <Radio value={"Yes"} selected={false} onClick={() => clicked("yes")}/>
    <Radio value={"No"} selected={false} onClick={() => clicked("no")}/> */}
    {/* <button className="NoButton" onClick={() => clicked("no")}>No</button><button className="YesButton" onClick={() => clicked("yes")}>Yes</button> */}
    </div>
  );
};

export default YesNoButton;