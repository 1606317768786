import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle} from "react";
import axios from "axios";
import Autocomplete from "react-google-autocomplete";

import "./FlowFour.css"

import TextField from "../Utils/TextField";
import Radio from "../Utils/Radio";
import TextArea from "../Utils/TextArea";

import useLocalStorage from "../Utils/Storage"

const FlowFour = forwardRef((props, ref) => {

  const [id, setId] = useLocalStorage("id", "");

  const [name, setName] = useLocalStorage("name", "");
  const [birthday, setBirthday] = useLocalStorage("birthday", "");

  const [gender, setGender] = useLocalStorage("gender", "");
  const [genderIndex, setGenderIndex] = useLocalStorage("genderIndex", -1);

  const [location, setLocation] = useLocalStorage("location", "");
  const [bio, setBio] = useLocalStorage("bio", "");

  const [email, setEmail] = useLocalStorage("email", "");
  const [subscribe, setSubscribe] = useLocalStorage("subscribe", true);

  const [error, setError] = useState("");

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  const errorCheck = () => {
    if (!name || !birthday || !gender || !location || (email != "" && !validateEmail(email))) {
      return true
    } else {
      setError("")
      return false
    }
  }

  useEffect(() => {
      errorCheck()
  }, [name, birthday, gender, location, email])

  useImperativeHandle(ref, () => ({
    next() {
        if (errorCheck()) {
          setError("Please fill out all required fields.")
          return false
        } else if (email != "" && !validateEmail(email)) {
          setError("Please enter a valid email")
        } else {
            return true
        }
    }
  }));

  const genderChosen = (newGender) => {
    console.log(newGender);
    setGender(newGender);
    if (newGender == "male") {
      setGenderIndex(1);
    } else if (newGender == "female") {
      setGenderIndex(2);
    } else if (newGender == "non-binary") {
      setGenderIndex(3);
    } else {
      setGenderIndex(4);
    }
  }

  return (
    <div className="FlowFour">
        <div className="AppHeader">
          Tell us about you
        </div>
        <div className="AppFLowError">
          {error}
        </div>
        <div className="FlowFourHeader">your name</div>
        <TextField placeholder="your name" value={name} onChange={(e) => setName(e.target.value)}/>
        <div className="FlowFourHeader">your birthday</div>
        <TextField placeholder="your birthday" type="date" value={birthday} onChange={(e) => setBirthday(e.target.value)}/>
        <div className="Genders">
            <div className="FlowFourHeader">your gender</div>
            <div className="Gender">
            <Radio value="male" onClick={() => genderChosen("male")} selected={1 == genderIndex} width="100px"/>
            </div>
            <div className="Gender">
            <Radio value="female" onClick={() => genderChosen("female")} selected={2 == genderIndex} width="100px"/>
            </div>
            <div className="Gender">
            <Radio value="non-binary" onClick={() => genderChosen("non-binary")} selected={3 == genderIndex} width="130px"/>
            </div>
            <TextField placeholder="other" value={(4 == genderIndex) ? gender : "" } onChange={event => genderChosen(event.target.value)}/>
        </div>
        <div className="LocationTextFieldParent">
        <div className="FlowFourHeader">your hangout spot</div>
        <Autocomplete
          className='LocationTextField'
          apiKey={"AIzaSyBlX1-ZS3iIqkzw82Pl41oIpAgT__cnLRw"}
          onChange={(e) => setLocation(e.target.value)}
          value={location}
          onPlaceSelected={(place) => {
            console.log(place);
          }}
          options={{
            types: ["geocode", "establishment"],
          }}
        />
        </div>
        <div className="FlowFourHeader">your bio</div>
        <TextArea placeholder="tell us more about you (optional)" value={bio} onChange={(e) => setBio(e.target.value)}/>
        {id && id != "" &&
        <div>
        <div className="FlowFiveHeader">your email</div>
        <div className="Email">
          <TextField type="email" placeholder="your email (optional)" value={email} onChange={(e) => setEmail(e.target.value)}/>
          <input className="ContactRadio" type="radio" id="email" name="ContactType" value="email" onClick={() => setSubscribe(!subscribe)} checked={subscribe}/>
          <label className="ContactRadioLabel" for="email">Subscribe to our mailing list</label>
        </div>
        <div className="PrivacyTerms">
          By pressing <b>next</b> you agree to the <b>TOS</b> and <b>Privacy Policy</b>.
        </div>
        </div>
        }
    </div>
  );
});

export default FlowFour;