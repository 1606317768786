import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle} from "react";

import "./FlowFive.css"

import TextField from "../Utils/TextField";

import useLocalStorage from "../Utils/Storage"

const FlowFive = forwardRef((props, ref) => {

  const [phone, setPhone] = useLocalStorage("phone", "");
  const [email, setEmail] = useLocalStorage("email", "");

  const [phoneChecked, setPhoneChecked] = useLocalStorage("phoneChecked", true);
  const [subscribe, setSubscribe] = useLocalStorage("subscribe", true);

  const [error, setError] = useState("");

  function formatPhone(value) {
    var phoneNumber = value.replace(/[^0-9.]/g, '')
    setPhone(phoneNumber);
  }

  function formatEmail() {
    
  }

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    if (phone && (email == "" || validateEmail(email))) {
      setError("")
    }
  }, [phone, email])

  useImperativeHandle(ref, () => ({
    next() {
        if (!phone) {
            setError("Please add a phone number")
            return false
        } else if (email != "" && !validateEmail(email)) {
          setError("Please enter a valid email")
          return false
        } else {
            return true
        }
    }
  }));
  
  return (
    <div className="FlowFive">
      <div className="AppHeader">
        How can we reach you?
      </div>
      <div className="AppFLowError">
          {error}
        </div>
      <div className="FlowFiveHeader">your phone number</div>
      <div className="PhoneNumber">
        <TextField placeholder="your phone number" value={phone} onChange={(e) => formatPhone(e.target.value)}/>
        {/* <input className="ContactRadio" type="radio" id="phone" name="ContactType" value="phone" onClick={() => setPhoneChecked(true)} checked={phoneChecked}/> */}
        {/* <label className="ContactRadioLabel" for="phone">reach me by number <b>✨ recommended ✨</b> </label> */}
      </div>
      <div className="FlowFiveHeader">your email</div>
      <div className="Email">
        <TextField type="email" placeholder="your email (optional)" value={email} onChange={(e) => setEmail(e.target.value)}/>
        <input className="ContactRadio" type="radio" id="email" name="ContactType" value="email" onClick={() => setSubscribe(!subscribe)} checked={subscribe}/>
        <label className="ContactRadioLabel" for="email">Subscribe to our mailing list</label>
      </div>
      <div className="PrivacyTerms">
        By pressing <b>next</b> you agree to the <b>TOS</b> and <b>Privacy Policy</b>.
      </div>
    </div>
  );
});

export default FlowFive;