import React, { useEffect, useState } from "react";
import './Radio.css'

const Radio = ({
  name,
  onChange,
  onClick,
  placeholder,
  required,
  value,
  width,
  selected=false
}) => {
  return (
    <div className="RadioParent">
    <div className="Radio" clicked={selected.toString()} onClick={onClick} style={{width: width}}>
        <div className="RadioInput" id="radio" clicked={selected.toString()}></div>
        <label className="RadioLabel" for="radio" clicked={selected.toString()}>{value}</label>
    </div>
    </div>
  );
};

export default Radio;