import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";

import logo from './logo.svg';
import './App.css';

import ThankYou from "./ThankYou"

import SquareButton from "./Utils/SquareButton"

import FlowYesNo from "./Flow/FlowYesNo";

import FlowOne from "./Flow/FlowOne";
import FlowTwo from "./Flow/FlowTwo";
import FlowThree from "./Flow/FlowThree";
import FlowFour from "./Flow/FlowFour";
import FlowFive from "./Flow/FlowFive";

import EventSorting from "./Flow/EventSorting";

import Button from "./Utils/Button"

import useLocalStorage from "./Utils/Storage"
import postData from "./Utils/PostUserData"

function Flow() {
    
  const {id} = useParams()
  const [userId, setId] = useLocalStorage("id", "");

  useEffect(() => {
    if (id && id != "") {
        // TODO: Validate ID
        setId(id);
    }
  }, [])


  const [finished, setFinished] = useState(false);

  const [pageIndex, setPageIndex] = useLocalStorage("pageIndex", 0);

  const [pageComplete, setPageComplete] = useLocalStorage("pageComplete", false);
  const [throwError, setThrowError] = useLocalStorage("throwError", false);

  const flowRef = useRef();

  var pages = [<FlowOne ref={flowRef}/>, <EventSorting setPageIndex={setPageIndex} ref={flowRef}/>, <FlowFour ref={flowRef}/>, <FlowFive ref={flowRef}/>];

  const nextPage = () => {
    if ((!((id && id != "") && pageIndex + 2 == pages.length)) && (pageIndex + 1 < pages.length) && flowRef.current.next()) {
        setPageIndex(pageIndex + 1);
    }

    if (((pageIndex + 1 == pages.length) || ((id && id != "") && pageIndex + 2 == pages.length)) && flowRef.current.next()) {
      setFinished(true)
      postData()
    }
  }

  const previousPage = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1)
    }
  }

  return (
    <div className="App">
      {finished &&
      <ThankYou/>
      }
      {!finished &&
      <div>
      <center>
      <div className="FlowPage">
      {!finished &&
        pages[pageIndex]
      }
      </div>
      </center>
      <div className="FlowGradient">
        <div className="FlowButtons">
          <div className="FlowButtonBack">
          {!finished && (pageIndex != 0) &&
            <Button value="back" onClick={() => previousPage()}/>
          }
          </div>
          <div className="FlowButtonNext">
          {!finished && pageIndex != 1 &&
            <Button value="next" onClick={() => nextPage()}/>
          }
          </div>
        </div>
      </div>
      </div>
    }
    </div>
  );
}

export default Flow;
