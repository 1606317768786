import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle} from "react";
import axios from "axios";

import "./FlowOne.css"

import Radio from "../Utils/Radio";
import TextArea from "../Utils/TextArea";

import useLocalStorage from "../Utils/Storage"

const FlowOne = forwardRef((props, ref) => {
  const [reasons, updateReasons] = useLocalStorage("reasons", ["", "", "", ""]);

  const [error, setError] = useState("");

  useImperativeHandle(ref, () => ({
    next() {
        if (reasons.toString() == ",,,") {
            setError("Please add at least one reason.")
            return false
        } else {
            return true
        }
    }
  }));

  const radioChosen = (index, reason) => {
    let newReasons = [...reasons];
    if (index < 3) {
        if (reasons[index] == "") {
            newReasons[index] = reason
        } else {
            newReasons[index] = ""
        }
    } else {
        newReasons[index] = reason
    }
    updateReasons(newReasons);

    if (newReasons.toString() != ",,,") {
        setError("")
    }
  };

  return (
    <div className="FlowOne">
        <div className="AppHeader">
            What brings you here?
        </div>
        <div className="AppFLowError">
            {error}
        </div>
        <div className="FlowOneButtons">
            <div className="FlowOneButton">
                <Radio value={"I want to meet new people"} selected={reasons[0] != ""} onClick={() => radioChosen(0, "people")}/>
            </div>
            <div className="FlowOneButton">
                <Radio value={"I want to learn"} selected={reasons[1] != ""} onClick={() => radioChosen(1, "learn")}/>
            </div>
            <div className="FlowOneButton">
                <Radio value={"I want to have fun"} selected={reasons[2] != ""} onClick={() => radioChosen(2, "fun")}/>
            </div>
        </div>
        <div className="FlowOneTextArea">
            <TextArea placeholder={"Tell us more (optional)"} value={reasons[3]} onChange={(event) => radioChosen(3, event.target.value)}/>
        </div>
    </div>
  );
});

export default FlowOne;