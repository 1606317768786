import * as React from "react";
import { useRef } from "react";
import './TextField.css'

const TextField = ({
  name,
  type,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  required,
  value
}) => {
  const inputElement = useRef();

  function timeout(number) {
    return new Promise( res => setTimeout(res, number) );
  }

  function clicked() {
    inputElement.current.blur();
    inputElement.current.type = "date"
    timeout(4);
    inputElement.current.focus();
  }

  return (
    <div className="TextFieldParent">
    <input
        className="TextField"
        type={type}
        name={name}
        onChange={onChange}
        onFocus={onFocus} 
        onBlur={onBlur}
        placeholder={value == "" ? placeholder : ""}
        value={value}
        ref={inputElement}
        required={required}/>
    </div>
  );
};

export default TextField;