import * as React from "react";
import './CompareButton.css'

const CompareButton = ({
  title,
  description,
  name,
  type,
  onChange,
  onClick,
  placeholder,
  required,
  imageUrl,
  clicked,
  value
}) => {

  return (
    <div className="CompareButton" onClick={() => clicked(name)}>
    <div className="CompareButtonImage" style={{backgroundImage: "url(" + imageUrl +")"}}></div>
    <div className="CompareButtonText">
        <div className="CompareButtonHeader">{title}</div>
        <div className="CompareButtonDescription">{description}</div>
    </div>
    </div>
  );
};

export default CompareButton;