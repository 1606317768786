import * as React from "react";
import './Button.css'

const Button = ({
  name,
  type,
  onChange,
  onClick,
  placeholder,
  required,
  value
}) => {
  return (
    <button
        className="Button"
        type={type}
        name={name}
        onChange={onChange}
        onClick={onClick}
        placeholder={placeholder}
        required={required}>{value}</button>
  );
};

export default Button;