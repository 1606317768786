import logo from './logo.svg';
import './App.css';

import hexagons from 'svg-patterns/p/hexagons';
import stringify from 'virtual-dom-stringify';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './Home';
import Flow from './Flow';

const pattern = hexagons({
  size: 50, 
  fill: 'none',
  strokeWidth: 1,
  stroke: '#F6DED0',
  background: null 
});

function App() {
  return (
    <div className="App">
      <svg className="Content">
      <defs dangerouslySetInnerHTML={{ __html: stringify(pattern) }}></defs>
      <rect width="100%" height="100%" style={{ 'fill': pattern.url()}}/>
      </svg>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/flow/:id" element={<Flow/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
