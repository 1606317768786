import axios from "axios";

const getStorageValue = (key, defaultValue) => {
    const saved = localStorage.getItem(key);
    const initial = JSON.parse(saved);
    return initial || defaultValue;
}

const createUser = (data) => {
  console.log(data);
    const api = axios.create({baseURL: 'https://tbblsh3uvi.execute-api.us-west-1.amazonaws.com/V0'})
    api.post('/user', data).then((response) => {
      console.log(response);

      var custom_id = response.data.body.custom_id;

      console.log(response);


    }).catch((error) => {
      console.log("error", error);
    });
  }

const postData = () => {
    var keys = [
        "id",
        "name",
        "birthday",
        "gender",
        "location",
        "bio",

        // "phone",
        "email",
        "subscribe",

        "reasons",

        "noEvents",
        "yesEvents"
    ]

    var data = {}
    for (const key of keys) {
        data[key] = getStorageValue(key);
        localStorage.removeItem(key);
    }
    localStorage.removeItem("pageIndex");
    localStorage.setItem("pageIndex", JSON.stringify(0));
    localStorage.removeItem("genderIndex");

    createUser(data)
}

export default postData;