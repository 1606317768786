import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import "./EventSorting.css";

import useLocalStorage from "../Utils/Storage"

import YesNoButton from "../Utils/YesNoButton"
import CompareButton from "../Utils/CompareButton"

const events = [
  {
    title: "Beach Blaze Bonanza",
    description: "A campfire by the beach surrounded by good vibes and great people",
    imageUrl: require("../EventImages/fire.jpeg"),
  },
  {
    title: "Sip and Giggles Tea Party",
    description: "Biscottis, tea and engaging conversations",
    imageUrl: require("../EventImages/tea.jpeg"),
  },
  {
    title: "Beet Jams and Kaleidoscope Vibes",
    description: "Live music at a vegan restaurant",
    imageUrl: require("../EventImages/music.jpeg"),
  },
  {
    title: "Stranger Supper Soiree",
    description: "A dinner party where you don't know anyone",
    imageUrl: require("../EventImages/food.jpeg"),
  },
  {
    title: "High Stakes Catan",
    description: "Ice cream, snacks and an intense board game tournament",
    imageUrl: require("../EventImages/board.jpeg"),
  },
  {
    title: "Spike Ball and the Outdoors",
    description: "Exercise and get some fresh air while playing games with great people",
    imageUrl: require("../EventImages/game.jpeg"),
  }
]

const EventSorting = forwardRef(({setPageIndex}, ref) => {

  const [noEve, setNoEve] = useLocalStorage("noEvents", []);
  const [yesEve, setYesEve] = useLocalStorage("yesEvents", []);

  const [yesEvents, setYesEvents] = useState([]);
  const [noEvents, setNoEvents] = useState([]);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [compareEventIndex, setCompareEventIndex] = useState(0)
  
  const [sortNoPrompt, setSortNoPrompt] = useState(false); 
  const [sortYesPrompt, setSortYesPrompt] = useState(false);

  useEffect(() => {
    if (currentEventIndex == events.length) {
      // setPageIndex(2);
      console.log(noEve, yesEve)
    }
  }, [currentEventIndex])

  useEffect(() => {
    var yes = []
    for (let i = 0; i < yesEvents.length; i++) {
      yes.push(yesEvents[i].title);
    }
    setYesEve(yes);
    var no = []
    for (let i = 0; i < noEvents.length; i++) {
      no.push(noEvents[i].title);
    }
    setNoEve(no);
  }, [noEvents, yesEvents])

  useEffect(() => {
    if (currentEventIndex == events.length && noEve.length + yesEve.length == events.length) {
      console.log(noEve, yesEve)
      setPageIndex(2);
      
      setNoEvents([]);
      setYesEvents([]);
      setCurrentEventIndex(0);
    }
  }, [noEve, yesEve])

  const handleSelection = (answer) => {
    if (answer === "yes") {
      if (yesEvents.length == 0) {
            setYesEvents((prevYesEvents) => [...prevYesEvents, events[currentEventIndex]]);
            setCurrentEventIndex(currentEventIndex + 1);
      } else {
            setCompareEventIndex(0);
            setSortYesPrompt(true);
      }
    } else if (answer === "no") {
        if (noEvents.length == 0) {
            setNoEvents((prevNoEvents) => [...prevNoEvents, events[currentEventIndex]]);
            setCurrentEventIndex(currentEventIndex + 1);
        } else {
            setCompareEventIndex(0);
            setSortNoPrompt(true);
        }
    }

    if (sortYesPrompt) {
        if (answer == "compare" && (compareEventIndex >= Math.floor(yesEvents.length / 2))) {
            var newYesEvents = [...yesEvents];
            newYesEvents.splice(compareEventIndex+1, 0, events[currentEventIndex]);
            setYesEvents(newYesEvents);
            setSortYesPrompt(false);
            setCurrentEventIndex(currentEventIndex + 1);
            setCompareEventIndex(0);
        } else if (answer == "compare") {
            setCompareEventIndex(yesEvents.length - 1 - compareEventIndex);
        }

        if (answer == "current" && (compareEventIndex <= Math.floor(yesEvents.length / 2))) {
            var newYesEvents = [...yesEvents];
            newYesEvents.splice(compareEventIndex, 0, events[currentEventIndex]);
            setYesEvents(newYesEvents);
            setSortYesPrompt(false);
            setCurrentEventIndex(currentEventIndex + 1);
            setCompareEventIndex(0);
        } else if (answer == "current") {
            setCompareEventIndex(yesEvents.length - compareEventIndex);
        }
    }

    if (sortNoPrompt) {
        if (answer == "compare" && (compareEventIndex >= Math.floor(noEvents.length / 2))) {
            var newNoEvents = [...noEvents];
            newNoEvents.splice(compareEventIndex+1, 0, events[currentEventIndex]);
            setNoEvents(newNoEvents);
            setSortNoPrompt(false);
            setCurrentEventIndex(currentEventIndex + 1);
            setCompareEventIndex(0);
        } else if (answer == "compare") {
            setCompareEventIndex(noEvents.length - 1 - compareEventIndex);
        }

        if (answer == "current" && (compareEventIndex <= Math.floor(noEvents.length / 2))) {
            var newNoEvents = [...noEvents];
            newNoEvents.splice(compareEventIndex, 0, events[currentEventIndex]);
            setNoEvents(newNoEvents);
            setSortNoPrompt(false);
            setCurrentEventIndex(currentEventIndex + 1);
            setCompareEventIndex(0);
        } else if (answer == "current") {
            setCompareEventIndex(noEvents.length - compareEventIndex);
        }

        console.log(noEvents, yesEvents)
    }

  };

  return (
    <div className="FlowYesNo">
      {!(sortNoPrompt || sortYesPrompt) && (
        <div>
      <div className="AppHeader">
        Would you go to this event?
      </div>
      <div className="TopSpacing"/>
      <div>
      <YesNoButton yes={yesEvents.find(obj => obj.title === events[currentEventIndex]?.title) != null} no={noEvents.find(obj => obj.title === events[currentEventIndex]?.title) != null} clicked={handleSelection} title={events[currentEventIndex]?.title} description={events[currentEventIndex]?.description} imageUrl={events[currentEventIndex]?.imageUrl}/>
      </div>
      </div>
      )}
      {sortNoPrompt && (
        <div>
        <div className="AppHeader">
        Which event do you like least?
        </div>
        <div className="TopSpacing"/>
        <CompareButton name="current" clicked={handleSelection} title={events[currentEventIndex]?.title} description={events[currentEventIndex]?.description} imageUrl={events[currentEventIndex]?.imageUrl}/>
        <CompareButton name="compare" clicked={handleSelection} title={noEvents[compareEventIndex]?.title} description={noEvents[compareEventIndex]?.description} imageUrl={noEvents[compareEventIndex]?.imageUrl}/>
        </div>
      )}
      {sortYesPrompt && (
        <div>
        <div className="AppHeader">
        Which event would you rather attend?
        </div>
        <div className="TopSpacing"/>
        <CompareButton name="current" clicked={handleSelection} title={events[currentEventIndex]?.title} description={events[currentEventIndex]?.description} imageUrl={events[currentEventIndex]?.imageUrl}/>
        <CompareButton name="compare" clicked={handleSelection} title={yesEvents[compareEventIndex]?.title} description={yesEvents[compareEventIndex]?.description} imageUrl={yesEvents[compareEventIndex]?.imageUrl}/>
        </div>
      )}
    </div>
  );
});

export default EventSorting;
